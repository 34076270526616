import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import axios from "axios"
import VueAxios from "vue-axios";
import router from './router'
import store from './store'
import Multiselect from 'vue-multiselect';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate'

// Import Theme scss
import './assets/scss/app.scss'
import './assets/scss/custom.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios)
Vue.component('multiselect', Multiselect);

// axios.defaults.baseURL = "http://localhost:3000/api";
axios.defaults.baseURL = "https://registro.psicometrix.cl/api";


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
